.filters-outer{
    padding-top: 0px;
}
.individual-facet {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 45px;
    color: #3C3D3B;
}
.filters{
    list-style-type: none;
    padding: 10px 0px 0px 0px;
    border-bottom: 1px solid #E2E2E2;
}
.filter-class{
    background-color: white;
    border-radius: 5px 5px 5px 5px;
}
.filter-list {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #808285;
    cursor: pointer;
}
.filter-search-icon {
    line-height: 45px;
    cursor: pointer;
}

.filter-nanobar{
    height: 4px;
    margin-top: 2%;
    margin-bottom: -0.5px;
    background: linear-gradient(90deg, #E8BEE1 8.85%, #CD9DC5 91.92%);
}
.search-filter-input {
    width: 100%;
    height: 35px;
    border-radius: 2px;
    border: 1px solid #D5A9CE;
    font-size: 14px;
    padding-left: 10px;
    color: #3c3d3b ;
    font-family: 'Muli' , 'sans-serif';
}


#filter-value-default{
    color:#808285;
 }
 #filter-value-active{
    color:#000;
    font-weight:bold
 }
 #filter-value-count-active{
     color:#3c3d3b;
     font-weight: 600;
 }
#expand-icon{
    float: right;
    color:#4c4c4c;
    font-size:12px;
}
#filter-value-count-active{
    color:#3c3d3b;
    font-weight: 600;
}
#filter-right-content{
    padding:5%;
    border-left: 0px solid transparent;
    margin: 0%;
    padding: 8% 5% 7% 5%;
    color: #3C3D3B;
}
#filter-right-content-active{
    border-left: 4px solid #C44E4E;
    padding: 8% 5% 7% 5%;
    margin: 0%;
}
#searched-query {
    font-family: Muli;
    font-size: 13px;
    color: #676666;
    text-align: center;
    border: 1.5px solid #D5A9CE;
    border-radius: 20px;
    margin-left: 8px;
    padding: 0 10px;
    font-weight: normal;
    line-height: 25px;
    cursor: default;
}
.tag-basic-btn {
    font-family: Muli,"sans-serif";
    font-weight: 700;
    font-size: 14px;
    border-radius: 30px;
    padding: 0px 15px;
    line-height: 2.6;
    display: inline-flex;
}
.search-term-input {
    font-size: 20px;
    color: #4c4c4c;
    text-align: left;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}