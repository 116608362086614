@import "../../Design/color.scss";

.custom-scrollbar-checkbox::-webkit-scrollbar {
  width: 5px;
  height: 32px;
}
.custom-scrollbar-checkbox::-webkit-scrollbar-track {
  border: 1.5px solid transparent;
  background-clip: content-box;
  background-color: #e3e3e3;
}
.custom-scrollbar-checkbox {
  overflow-y: scroll;
  max-height: 170px;
}
.custom-scrollbar-checkbox::-webkit-scrollbar-thumb {
  background-color: $color-lilac;
  border-radius: 30px;
  height: 5px;
}

//Dark Mode
.custom-scrollbar-checkbox-dark::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.custom-scrollbar-checkbox-dark::-webkit-scrollbar-track {
  border: 1.5px solid transparent;
  background-clip: content-box;
}
.custom-scrollbar-checkbox-dark {
  overflow-y: scroll;
  max-height: 170px;
}
.custom-scrollbar-checkbox-dark::-webkit-scrollbar-thumb {
  background-color: #a98e9c;
  border-radius: 30px;
  height: 5px;
}

/*Horizontal Scroll*/

.custom-horizontal-scroll::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}
.custom-horizontal-scroll::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.custom-horizontal-scroll {
  overflow-x: scroll;
  overflow-y: none;
}
.custom-horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: $color-lilac;
  height: 10px;
}
.custom-horizontal-scroll::-webkit-scrollbar-button {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
}
.custom-horizontal-scroll::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("../../../public/static_images/horizontal-scroll-arrow-right.svg");
}
.custom-horizontal-scroll::-webkit-scrollbar-button:start:decrement {
  background-image: url("../../../public/static_images/horizontal-scroll-arrow-left.svg");
}