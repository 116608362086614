.mt-6 {
  margin-top: 4rem !important;
}

.draft-row {
  display: flex;
  justify-content: flex-start;
}

.draft-count-box {
  font-size: 10px;
  color: #8c8c8c;
}

.count-divider {
  width: 5px;
  background-color: #88305f;
  margin: 0 8px;
}

.draft-list-view-container {
  max-height: "480px";
  overflow-y: auto;
}

.draft-list-view {
  cursor: pointer;
  border-bottom: 1px solid #c6c6c6;
}

.draft-list-view-header {
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1d1d1d;
  height: 40px;
  padding-left: 14px;
  border-bottom: 1px solid #c6c6c6;
}

.draft-list-view-header div {
  padding: 9px;
  height: 40px;
}

.draft-list-view-table div {
  margin-top: 3px;
}

.MuiAppBar-root {
  border-bottom: 1px solid #e1e1e1 !important;
}

.draft-dialog .MuiDialog-paperWidthMd {
  max-width: 830px !important;
}

.MuiAccordion-root:before {
  // background-color: transparent !important;
  height: 1.5px !important;
}

.MuiAccordion-root.Mui-expanded:before {
  opacity: 1 !important;
}

.accordian-head:nth-child(n + 2) {
  background-color: #fafafa;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTab-root {
  font-size: 1rem !important;
  text-transform: none !important;
}

.MuiAppBar-colorPrimary {
  color: #88305f !important;
  background-color: #ffffff !important;
}

.MuiTab-root {
  min-width: 110px !important;
}

.draft-tabs .MuiTabs-indicator {
  background-color: transparent !important;
}

.draft-tabs .MuiTab-textColorInherit.Mui-selected {
  background-color: #88305f;
  color: white;
  border-radius: 25px;
}

.MuiTabs-indicator {
  height: 3px !important;
}

.draft-count {
  font-size: 30px;
  color: #996c84;
}

.margin-0 {
  margin-bottom: 0;
}

.list-view-header div {
  padding: 9px;
  height: 40px;
}

.create-btn {
  background: #ffef5a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #ffef5a, #fbce2f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #ffef5a, #fbce2f);
  border: none;
  font-size: 14px;
  color: #626262;
}

.draft-card {
  box-shadow: 0px 2px 20px 0px #8787871a;
  background-color: white;
  border-radius: 10px;
}

.draft-icon {
  width: 31px;
}

.text-purple {
  font-weight: 500;
  color: #88305f;
  font-size: 15px;
}

.cancel-btn {
  font-size: 14px !important;
  color: #88305f !important;
  font-weight: 700 !important;
}

.save-btn {
  font-size: 15 !important;
  font-weight: 700 !important;
}

.delete-icon {
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.project-save-btn {
  color: #88305f !important;
  border: 1px solid #88305f !important;
  font-weight: 600 !important;
}

.draft-checkbox {
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0;
  width: 0;
  position: relative;
  right: 25px;
  top: 6px;
  z-index: 2;
}

.primary-checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #88305f;
}

// .draft-dialog .MuiDialog-paperWidthSm {
//     max-width: 750px !important;
// }

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: #df0d00;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    // top: $size * 0.1;
    top: 2px;
    font-size: $size * 0.4;
    line-height: $size * 0.7;
    color: #fff;
    font-family: Roboto;
    font-weight: normal;
  }
}

.avatar-circle {
  @include avatar(100px);
}

.avatar-circle-sm {
  @include avatar(30px);
}

.avatar-circle-lg {
  @include avatar(250px);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

svg.reload-icon {
  width: 18px;
  height: 18px;
  stroke-width: 2em;
  color: "#88305F";
}

svg.edit-icon {
  width: 21px;
  height: 21px;
  stroke-width: 1em;
  margin: 0 10px;
}

svg.svg-delete-icon {
  width: 21px;
  height: 21px;
  margin: 0 10px;
}

.selected-draft {
  background-color: #f0e1e9 !important;
}

img.checked-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: -3px;
  left: -2px;
}

.version-history {
  width: 680px;
  padding: 15px;
  background-color: #f9f9f9;
  height: 100%;
  &__info {
    background-color: #ebebeb;
    padding: 10px;
    border-radius: 8px;
  }

  &__table-header {
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0;
    margin: 0 -5px;
    border-bottom: 1px solid #c6c6c6;
  }

  &__table-list {
    cursor: pointer;
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0;
    margin: 0 -5px;
    align-items: center;
  }
}
.support-doc {
  width: 620px;
  padding: 15px;
  background-color: #f9f9f9;
  height: 100%;
  font-size: 14px;
  &__info {
    background-color: #ebebeb;
    padding: 10px;
    border-radius: 8px;
  }

  &__table-header {
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0;
    margin: 0 -5px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__table-list {
    cursor: pointer;
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0;
    margin: 0 -5px;
    align-items: center;
  }
}

.support-doc-2 {
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  height: 100%;
  font-size: 14px;
  &__info {
    background-color: #ebebeb;
    padding: 10px;
    border-radius: 8px;
  }

  &__table-header {
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0;
    margin: 0 -5px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__table-list {
    cursor: pointer;
    border-bottom: 1px solid #c6c6c6;
    padding: 10px 0;
    margin: 0 -5px;
    align-items: center;
  }
}

svg.back-arrow-icon {
  stroke-width: 0.5px;
  width: 20px;
  height: 20px;
}

svg.upload-icon {
  stroke-width: 0.5px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.upload-file {
  &__container {
    height: 100%;
    border: 1px dashed #d9d9d9;
  }

  &__action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__list {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.upload-dialog .MuiDialog-paperWidthMd {
  height: 480px;
}

.draft-tab .MuiTab-root {
  font-size: 19px !important;
  max-width: 300px !important;
  font-weight: 600 !important;
  min-width: 65px !important;
}
.draft-tab .MuiTab-textColorInherit.Mui-selected {
  color: #88305f;
}
.draft-main .MuiDialog-paperFullScreen {
  background-color: #e5e5e5;
}
.draft-tab {
  // padding: 0px 105px 0px 0px;
  background-color: #fff;
}
.draft-main .MuiBox-root-25 {
  padding: 10px 10px !important;
}
.draft-tab .MuiTabs-fixed {
  overflow: hidden !important;
}
.draft-main .MuiTabs-fixed::-webkit-scrollbar {
  display: none;
}

.text-container {
  border-radius: 0px 16px 16px 16px;
}
.tab-container .MuiBox-root .MuiBox-root {
  padding: 0px 15px 0px 0px;
}
.checkbox-labal .MuiTypography-body1 {
  font-size: 14px;
}
.show-check .MuiCheckbox-colorSecondary.Mui-checked {
  color: #00668f;
  border: 1px solid #dddddd;
}

.custom-accordion .MuiPaper-root {
  background-color: transparent;
  color: #818181;
  box-shadow: none;
}
.custom-accordion .MuiAccordionSummary-root.Mui-expanded {
  border-left: none !important;
}
.custom-div {
  height: auto;
  min-height: 70px;
  border-radius: 8px;
  width: 98%;
}
.accordion-icon-change {
  width: 8%;
  background: #ffeef7;
  border-radius: 8px 0px 0px 8px;
}
.custom-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 16px;
  width: 100%;
  height: auto;
  left: 742px;
  background: #ffffff;
  border: 1px solid #d20319;
}

.rule-check-1 .MuiCheckbox-colorSecondary.Mui-checked {
  color: #ff685f;
  border: 1px solid #dddddd;
}

.rule-check-2 .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fbce2f;
  border: 1px solid #dddddd;
}
.document-content-container {
  height: 300vh !important;
}

.btn-deviation {
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 0.95) 0%,
    #fff8df 100%
  );
  border-radius: 5px;
  border: none;
  width: 162px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #424242;
}

.btn-deviation-cl {
  background: #88305f;
  border-radius: 5px;
  border: none;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 10px;
}

.custom-box-new {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 16px;
  width: 100%;
  height: auto;
  left: 742px;
  background: #ffffff;
  border: 1px solid #88305f;
  border-radius: 8px;
}
.container-table .MuiAccordionSummary-root.Mui-expanded {
  border-left: none !important;
}

.assigned-drop-down::before {
  border: none !important;
}

.btn-deviation-reject {
  background: #ff685f;
  border-radius: 5px;
  border: none;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: white;
  padding: 10px;
}

.notify-card {
  height: auto;
  width: auto;
  border-radius: 8px;
  background-color: white;
  color: #818181;
  font-size: 16px;
  padding: 15px;
}

.from-status {
  height: auto;
  width: auto;
  background-color: #88305f;
  border-radius: 4px;
  padding: 8px 6px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.to-status {
  height: auto;
  width: auto;
  background-color: #00692a;
  border-radius: 4px;
  padding: 4px 6px;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.users-view {
  font-weight: 600;
  font-size: 16px;
  color: #00692a;
}

.disabled-buttons:hover {
  cursor: not-allowed;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(136, 48, 95) !important;
}
