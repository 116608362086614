.mt-6 {
    margin-top: 4rem !important;
}

.approvals-row {
    display: flex;
    justify-content: flex-start;
}

.approvals-count-box {
    border: 1px solid #EAEAEA;
    background-color: white;
    border-radius: 5px;
    margin-right: 47px;
    box-shadow: 0 4px 0 10px 10px;
    padding: 10px;
    font-size: 10px;
    color: #8C8C8C;
    width: 400px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.MuiAppBar-root {
    border-bottom: 1px solid #E1E1E1 !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.MuiTab-root {
    font-size: 1rem !important;
    text-transform: none !important;
}

.MuiAppBar-colorPrimary {
    color: #88305F !important;
    background-color: #ffffff !important;
}

.MuiTabs-indicator {
    background-color: #88305F !important;
}

.MuiTabs-indicator {
    height: 3px !important;
}

.approvals-count {
    font-size: 30px;
    color: #996C84;
}

.margin-0 {
    margin-bottom: 0;
}

.list-view-header div {
    padding: 9px;
    height: 40px;
}

.approvals-list-view-table {
    padding: 5px 0px;
    margin-right: 10px;
}

.approvals-list-view-table div {
    padding-left: 2rem;
}


.approvals-card {
    box-shadow: 0px 2px 20px 0px #8787871A;
    background-color: white;
    border-radius: 10px;
}

.approvals-icon {
    width: 31px;
}

.card-title {
    background-color: #f9f9f9;
    color: #555555;
    padding: 9px;
    margin: 0;
}

.card-container {
    padding: 9px;
}

.blue-box {
    border-radius: 5px;
    padding: 5px;
}

.text-purple {
    font-weight: 500;
    color: #88305F;
    font-size: 15px;
}

.hover:hover {
    cursor: pointer;
}


.cancel-btn {
    font-size: 14px !important;
    color: #88305F !important;
    font-weight: 700 !important;
}

.save-btn {
    font-size: 15 !important;
    font-weight: 700 !important;
}

.delete-icon {
    width: 20px;
    margin-top: 15px;
    cursor: pointer;
}

@mixin avatar($size) {
    width: $size;
    height: $size;
    // background-color: #df0d00;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;

    .initials {
        position: relative;
        // top: $size * 0.1;
        top: 2px;
        background-color: #df0d00;
        font-size: $size * 0.4;
        line-height: $size * 0.7;
        color: #fff;
        font-family: Roboto;
        font-weight: normal;
    }
}

.avatar-circle {
    @include avatar(100px);
}

.avatar-circle-sm {
    @include avatar(30px);
}

.avatar-circle-lg {
    @include avatar(250px);
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
}

.approval-subtitle {
    padding: 10px;
    background-color: #f9f9f9;
}

.approval-chip {
    border-radius: 20px;
    padding: 0 9px;
    color: rgb(0, 105, 42);
    background-color: rgb(216, 247, 230);
}