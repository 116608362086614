.cls_section_details {
  padding-left: 0;
}

.cls_data_dictionary_nav_bar {
  padding-right: 0;
}

.section_Field_Data {
  background-color: white;
  padding: 20px;
  height: 100%;
}

.field-container {
  background-color: white;
  box-shadow: 1px 2px 8px 0 rgb(0 0 0 / 20%);
}

.f-14 {
  font-size: 14px;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.section_field_container {
  padding: 20px;
  background-color: #f5f5f5;
  height: 100%;
}

.field-header {
  background-color: #88305f;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 35px;
}

.field-header-2 {
  background-color: #88305f;
  color: white;
  min-height: 35px;
}

.field-btn {
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 0.95) 0%,
    #fff8df 100%
  );
  border: none;
}

#field-header-input {
  padding: 2px 5px;
  border-radius: 5px;
  outline: none;
}

.delete-field-icon {
  display: inline-block;
  padding: 10px;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(254, 254, 254, 0.95) 0%,
    #fff8df 100%
  );
  float: right;
}

.contractSectionContent {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}


.MuiCardContent-root:last-child{
    padding-bottom: 20px !important;
}
.disabled-buttons:hover {
  cursor: not-allowed;
}
