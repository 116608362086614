.filter {
    width: 260px;
    padding: 10px;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    // max-height: 250px;
    overflow: hidden;
    &__list-box {
        height: calc(100% - 20px);
        overflow-y: auto;
    }
}
// ::-webkit-scrollbar {
//     display: none;
// }