.cls_list_main_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cls_header_title {
  font-weight: 700;
}

.cls_last_updated_details {
  font-size: 10px;
  font-family: muli;
}

.view-all-card {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 10px #dddddd;
}

.view-all-textalign .MuiTableCell-alignRight {
  text-align: center;
}
.created-by-dd {
  background-color: red;
  width: 18%;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid purple;
  color: #88305f;
  font-weight: 700;
  background-color: #ffeef7;
}

.view-icn {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.view-all-textalign .MuiTableCell-root.MuiTableCell-body {
  padding: 8px 16px 8px 16px;
}

.mui-field-custom .MuiOutlinedInput-notchedOutline {
  border: none;
  padding: 0px;
}

.mui-field-custom .MuiInputBase-root.MuiOutlinedInput-root.MuiOutlinedInput-multiline{
  padding: 0px;
}

@media screen and (min-width: 1024px) and (max-width:1150px) {
  .created-by-dd{
      width: 42%;
  }
}

@media screen and (min-width: 1150px) and (max-width:1300px) {
  .created-by-dd{
      width: 28%;
  }
}

@media screen and (min-width: 1300px) and (max-width:1600px) {
  .created-by-dd{
      width: 28%;
  }
}