.avatar-container {
    display: flex;

    &_avatar {
        border-radius: 50%;
        border: 1px solid #88305F;
        font-size: 14px;
        color: #88305F;
        padding: 5px;
        background-color: #FFEEF7;
    }
}