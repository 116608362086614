.cls_main_side_1 {
  height: calc(100vh - 200px);
  overflow-y: auto;
  border: 1px solid #dedede;
}

.cls_section_name,
.cls_section_description {
  margin-left: 6px;
  width: 92%;
  border: 1px solid #dddddd;
  padding: 3px 8px;
}

.submit-section {
  font-size: 14px;
  text-align: right;
  margin-right: 17px;
  color: #bbbbbb;
}

.MuiAccordionSummary-root {
  border-left: 3.5px solid transparent !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  border-left: 3.5px solid #88305f !important;
}

/* .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
} */

.MuiAccordionSummary-expandIcon {
  color: #88305f !important;
}

/* .MuiBox-root {
  padding: 0px !important;
  border-radius: 15px !important;
} */

.select-hover:hover {
  background-color: #f0e1e9 !important;
}

.select-hover.MuiListItem-root.Mui-selected{
  background-color: transparent;
}


.tooltip-custom {
  position: relative;
  display: inline-block;
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 120px;
  min-height: 10px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-custom .tooltiptext.active {
  visibility: visible;
  opacity: 1;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #88305F !important;
  border-width: 1px !important;
}

.disabled-buttons:hover {
  cursor: not-allowed;
}