.main_color {
  color: #88305f;
}

.orange {
  color: #ee5800;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #996C84 !important; 
}
