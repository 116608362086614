.cursor-pointer {
    cursor: pointer;
}
.upload-yellow-btn {
    border: none;
    padding: 1.3% 6%;
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;
    line-height: 18px;
    color: #4B4B4B;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
}
.upload-yellow-btn:active {
    outline: none;
}
.upload-disable-btn {
    border: none;
    outline: none;
    padding: 1.3% 6%;
    background: #E2E2E2;
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    font-family: Roboto;
    line-height: 18px;
    color: #6C6C6C;
    display: flex;
    align-items: center;
}
.align-left {
    text-align: left !important;
}
.align-right {
    text-align: right;
}
.dotted-line {
    /* border-bottom: 1.5px dotted #808080; */
    cursor: default;
}   
button:focus {
    outline: none;
}
.display-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.library-yellow-btn {
    border: none;
    background: linear-gradient(180deg, #FFEF5A 0%, #FBCE2F 100%);
    box-shadow: 1px 2px 10px rgba(6, 1, 48, 0.2);
    border-radius: 5px;
    font-size: 13px;
    font-weight: normal;
    font-family: Roboto;
    line-height: 15px;
    color: #4B4B4B;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
}
.library-yellow-btn:active {
    outline: none;
}
.library-disable-btn {
    border: none;
    outline: none;
    background: #E2E2E2;
    border-radius: 5px;
    font-size: 13px;
    font-weight: normal;
    font-family: Roboto;
    line-height: 15px;
    color: #6C6C6C;
    display: flex;
    align-items: center;
}