.filter-container {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  height: calc(100vh - 80px);
  min-width: 270px;
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding-right: 20px;
}

.filter-heading {
  font-weight: 600;
  color: #4c4c4c;
}

.text-align-right {
  text-align: right;
}

.filter-container-filter-header {
  color: #88305f;
  font-weight: 600;
}

.filter-reset {
  color: #818181;
  font-size: 12px;
}

.cp {
  cursor: pointer;
}

.filter-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.f-16 {
  font-size: 16px;
}

.pl-0 {
  padding-left: 0;
}

.search-bar {
  background-color: white;
}

.filter-list-header {
  border-bottom: 1px solid rgb(225, 225, 225);
  margin-left: 0rem;
  margin-right: 0rem;
  background-color: white;
}

.filter-table {
  margin-left: 0;
  padding: 10px 7px;
  margin-right: 0;
}

img.close-btn {
}

// .MuiSlider-root {
//     color: #88305F !important;
// }

.filter-title-layout .tooltip .tooltiptext {
  visibility: hidden;
  background-color: #ffffff;
  color: #665e5e;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 35%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ececec;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
}
