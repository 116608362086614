.page_navigation {
    align-items: center;
    justify-content: end;
    &__pageItem {
        padding: 5px 10px;
        border-radius: 5px;
    }
    &__button {
        cursor: pointer;
    }
}
.pagination-background .MuiPaginationItem-page.Mui-selected{
    background-color: #FFE5E8;
    color: #88305F;
}